/*===================== 
	Premery bg color 
=====================*/
:root{
	--bg-color: #b42e9e;
	--bg-color-sc: #3d56f0;
	--text-color-sc: #3d56f0;
	--bg-color-ho: #3d56f0;
	--text-color: #b42e9e;
}
/* Hex */
.meet-ask-row:after,
.dlab-separator.style-skew[class*="style-"]:after,
.dlab-separator.style-skew[class*="style-"]:before,
.dlab-tilte-inner.skew-title:after,
.date-style-2 .post-date,
.date-style-3.skew-date .post-date,
.date-style-3.skew-date .post-date:before,
.widget-title:after,
.btn,
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus,
.footer-line:after,
.testimonial-1 .quote-left:before,
.testimonial-2 .quote-left:before,
.site-filters .active > [class*="btn"],
.list-num-count > li:before,
.date-style-4 .post-date strong,
.date-style-3 .post-date,
.date-style-2 .post-date,
#myNavbar li.active a,
.skew-angle:after,
.header-style-4.style-1 .header-nav .nav > li.active a:before,
.header-style-4.style-1 .header-nav .nav > li:hover > a:before,
.dlab-project-left:after,
.service-carousel .owl-prev, 
.service-carousel .owl-next,
.about-us .dlab-tabs .nav-tabs li a.active,
.testimonial-text.bg-primary,
.sidenav ul li a:hover,
.owl-theme.dots-style-2 .owl-dots .owl-dot.active span,
.owl-btn-1.primary .owl-prev, 
.owl-btn-1.primary .owl-next,
.owl-btn-2.primary .owl-prev, 
.owl-btn-2.primary .owl-next,
.owl-slider-banner .owl-theme .owl-dots .owl-dot.active span, 
.owl-slider-banner .owl-theme .owl-dots .owl-dot:hover span,
.product-item-size .btn.active,
.ui-widget-header,
.owl-theme.dots-style-3 .owl-dots .owl-dot.active span, 
.owl-theme.dots-style-3 .owl-dots .owl-dot:hover span,
.owl-item.active.center .testimonial-text,
.icon-bx-wraper.hover:hover,
.portfolio-sep,
.portfolio-ic .dlab-img-overlay1:before,
.dlab-box-bg.active .icon-box-btn .btn,
.dlab-accordion.primary .acod-head a,
.side-bar .widget_tag_cloud a:hover,
.time-line:after,
.line-right:after,
.line-left:after,
.tabs-btn .nav-tabs li a.active:focus,
.tabs-btn .nav-tabs li a.active:hover,
.tabs-btn .nav-tabs li a.active,
.box-item-service:hover .item-service-content,
.box-item-service:hover h5,
.box-item-service:hover .icon-bx-md,
.tp-caption.rev-btn.tc-btnshadow.tp-rs-menulink,
.la-anim-10{
    background-color: #b42e9e;
}
/*Hex color :hover */
.btn:active,
.btn:hover,
.btn:focus,
.active > .btn,
.bg-primary-dark,
.pagination > li > a:hover, 
.pagination > li > span:hover, 
.pagination > li > a:focus, 
.pagination > li > span:focus ,
.pagination > .active > a, 
.pagination > .active > span, 
.pagination > .active > a:hover, 
.pagination > .active > span:hover, 
.pagination > .active > a:focus, 
.pagination > .active > span:focus,
.sidenav .closebtn:hover,
.sidenav .closebtn:focus,
.owl-btn-1.primary .owl-prev:hover, 
.owl-btn-1.primary .owl-next:hover,
.owl-btn-2.primary .owl-prev:hover, 
.owl-btn-2.primary .owl-next:hover,
.overlay-primary-light:after, 
.overlay-primary-middle:after, 
.overlay-primary-dark:after,
.overlay-primary:after{
    background-color: #3d56f0;
}
.about-us .dlab-tabs .nav-tabs li a.active{
	border-right: 4px solid #3d56f0;
}


/* Rgba color */
.dlab-info-has.bg-primary,
.dlab-info-has.bg-secondry:hover,
.tp-bannertimer.tp-bottom {
    background-color: rgba(108, 192, 0, 0.9);
}
/* Box Shadow */
.side-bar .widget_tag_cloud a:hover{
	box-shadow:5px 5px 10px 0 rgba(108, 192, 0, 0.15);
}
/*===================== 
	Premery text color 
=====================*/
a,
.text-primary,
.primary li:before,
.breadcrumb-row ul li a,
.header-nav .nav > li.active > a,
.header-nav .nav > li.current-menu-item > a .header-nav .nav > li .sub-menu li a:hover,
.header-nav .nav > li:hover > a,
.header-nav .nav > li .mega-menu > li ul a:hover,
.header-nav .nav > li .sub-menu li:hover > a,
.nav-dark.header-nav .nav > li .sub-menu li:hover > a,
.nav-dark.header-nav .nav > li .mega-menu > li ul a:hover,
blockquote:before,
ol.comment-list li.comment .reply a,
footer a:active,
footer a:focus,
footer a:hover,
footer h1 a,
footer h2 a,
footer h3 a,
footer h4 a,
footer h5 a,
footer h6 a,
footer p a,
button.scroltop,
.testimonial-1 .testimonial-position,
.testimonial-4 .testimonial-name:after,
.testimonial-2 .testimonial-position,
.testimonial-3 .testimonial-position,
.testimonial-3 .quote-left:before,
.acod-head a:after,
.acod-head a,
.acod-head a:hover,
.acod-head a.collapsed:hover,
.dlab-tabs .nav-tabs > li > a i,
h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover,
.btn.outline,
.btn-link:hover,
.item-info-in ul li a:hover,
.dlab-post-meta.text-primary i,
.dlab-post-meta.text-primary ul li,
.dlab-post-meta.text-primary ul li a,
.comments-area p:before,
.about-8-service .icon-bx-wraper:hover a,
.about-service .icon-bx-wraper:hover a,
.dlab-box-bg .icon-box-btn .btn,
.list-2 li:hover:before{
    color: #b42e9e;
}
@media only screen and (max-width: 991px) {
	.nav-dark.header-nav .nav > li > a:hover, 
	.nav-dark.header-nav .nav > li > a:active, 
	.nav-dark.header-nav .nav > li > a:focus{
		color: #b42e9e;
	}
}
.text-secondry{
	color:#3d56f0;
}
/*===================== 
	Premery border color 
=====================*/
.navbar-toggle,
.testimonial-4 .testimonial-pic,
.testimonial-4:after,
.testimonial-4 [class*="quote-"],
button.scroltop,
blockquote,
.widget_gallery li:hover,
.owl-slider-banner .owl-theme .owl-dots .owl-dot.active, 
.owl-slider-banner .owl-theme .owl-dots .owl-dot:hover,
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
input[type="checkbox"]:checked + label:before,
input[type="checkbox"] + label:hover:before, 
input[type="radio"] + label:hover:before,
.owl-theme.dots-style-3 .owl-dots .owl-dot.active, 
.owl-theme.dots-style-3 .owl-dots .owl-dot:hover,
.side-bar .widget_tag_cloud a:hover{
    border-color: #b42e9e;
}
.owl-item.active.center .testimonial-8 .testimonial-text:after{
	border-top:15px solid #b42e9e;
}
.dlab-tabs.border-top .nav-tabs > li > a.active{
	border-top-color: #b42e9e;
}
.port-style1 .port-box{
	border-left: 3px solid #b42e9e;
}
.choseus-tabs .nav li a.active{
	border-bottom: 2px solid #b42e9e;
}
svg.radial-progress:nth-of-type(6n+1) circle { stroke: #b42e9e; }
/*===================== 
	Secondery bg color
 =====================*/
.bg-secondry {
    background-color: #2d3239;
}
/* Duble Color */
.our-team.team-style1 .dlab-media:after,
.portfolio-bx.style2 .dlab-img-overlay1:before,
.portbox2:hover .dlab-media:after,
.dlab-img-overlay1:before, 
.dlab-img-overlay1:after, 
.dlab-img-overlay2:before, 
.dlab-img-overlay2:after, 
.dlab-img-overlay3:before, 
.dlab-img-overlay3:after, 
.dlab-img-overlay4:before, 
.dlab-img-overlay4:after, 
.dlab-img-overlay5:before, 
.dlab-img-overlay5:after, 
.dlab-img-overlay6:before, 
.dlab-img-overlay6:after, 
.dlab-img-overlay7:before, 
.dlab-img-overlay7:after, 
.dlab-img-overlay8:before, 
.dlab-img-overlay8:after, 
.dlab-img-overlay9:before, 
.dlab-img-overlay9:after, 
.dlab-img-overlay11:before, 
.dlab-img-overlay11:after, 
.dlab-img-overlay12:before, 
.dlab-img-overlay12:after,
.overlay-primary-light:after, 
.overlay-primary-middle:after, 
.overlay-primary-dark:after,
.overlay-primary:after,
.bg-primary{
	background-color:#191c0a;
}
.portbox2 .dlab-media:after{
	background: rgb(25,28,10);
    background: -moz-linear-gradient(top, rgba(25,28,10,0) 0%, rgba(25,28,10,1) 100%);
    background: -webkit-linear-gradient(top, rgba(25,28,10,0) 0%,rgba(25,28,10,1) 100%);
    background: linear-gradient(to bottom, rgba(25,28,10,0) 0%,rgba(25,28,10,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000',GradientType=0 );
}
